<template>
  <div class="container">
    <!-- 任务内容 -->
    <div class="item-content">
      <el-form label-position="left" label-width="100px" :model="task" :rules="rules">
        <!-- 任务名称 -->
        <el-form-item label="任务名称" prop="name" required>
          <el-input v-model="task.name" :disabled="isDisabled('name')"
            placeholder="最长不超过20字" maxlength="20" show-word-limit></el-input>
        </el-form-item>

        <!-- 任务目标 -->
        <el-form-item label="任务目标" required>
          <div style="width: 200px; float: left">
            <el-select v-model="task.targetType" placeholder="请选择任务目标" :disabled="isDisabled('name')">
              <el-option v-for="item in target" :key="item.value"
                :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: 200px; float: left; margin-left: 20px">
            <el-input v-model="task.targetCount" placeholder="请输入数量" :disabled="isDisabled('name')">
              <template slot="append">{{task.targetType==1?"次":"人"}}</template>
            </el-input>
          </div>
        </el-form-item>

        <!-- 指定内容 -->
        <el-form-item label="指定内容" required>
          <div>
            <el-radio v-model="task.contentLimit" :label="0" :disabled="isDisabled('name')">内容不限</el-radio>
            <el-radio v-model="task.contentLimit" :label="1" :disabled="isDisabled('name')">指定内容</el-radio>
          </div>
          <div v-if="task.contentLimit" @click="selectMatter" class="select-content"
            :style="{'pointer-events': isDisabled('name') ? 'none' : 'auto'}">
            <p v-if="!task.matterItem.type" class="tips">请选择内容</p>
            <task-content v-else :name="task.matterItem.item.title || task.matterItem.item.name"
              :img="(task.matterItem.item.logo != null && task.matterItem.item.logo)
                || (task.matterItem.item.configs && task.matterItem.item.configs.logo)"></task-content>
          </div>
        </el-form-item>

        <!-- 时间限制 -->
        <el-form-item label="时间限制" required>
          <el-date-picker v-model="task.times" type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            :clearable="false"
            start-placeholder="开始日期" range-separator="至" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <!-- 选择员工 -->
        <el-form-item label="选择员工">
          <el-button @click="selectStaff" type="primary" class="create-button">选择员工</el-button>
          <el-button type="text">已选择{{task.staffList.length}}名员工</el-button>
          <p class="tips">提示：不选择员工则默认对所有员工都生效</p>
        </el-form-item>

        <!-- 取消、确认按钮 -->
        <el-form-item>
          <el-button @click="$router.go(-1)" plain class="create-button">取消</el-button>
          <el-button type="primary" class="create-button" @click="updateTask">
            {{$route.query.id ? '保 存' : '创 建'}}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import dialogComponents from '@/websites/cms/js/dialog'
import TaskContent from '@/websites/cms/components/TaskContent'
export default {
  components: { 'task-content': TaskContent }, 
  data(){
    return {
      target: [
        {value: 0, label: "浏览"},
        {value: 1, label: "线索数"},
      ],
      task: {
        name: "",  // 任务名称                
        targetType: 0,  // 任务类型，浏览、分享
        targetCount: null,  // 任务目标次数或人数
        times: [this.$dayjs(), this.$dayjs().add(7, 'day')],
        contentLimit: 0,  // 是否指定内容
        contentId: "",
        staffList: [],
        matterItem: {},
        config: {}
      },
      rules: {
        name: [
          { required: true, message: '请输入任务名称', trigger: 'blur' },
          { min: 1, max: 20, message: '任务名称在 1 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created (){
    if(this.$route.query.id) this.getTask(this.$route.query.id);
  },
  methods: {
    // 查看任务详情
    async getTask(id){
      let res = await this.$ZGManager.getTaskDetail(id);
      if(res.status != 200) return;
      let item = res.data;
      this.task = {
        name: item.taskName,
        targetType: item.content,
        targetCount: item.times,
        times: [item.startTime, item.endTime],
        contentLimit: item.content,
        contentId: item.contentUrl,
        staffList: [],
        matterItem: {},
        config: JSON.parse(item.config)
      }
      if(item.config){
        this.task.matterItem = this.task.config.matter || {};
        this.task.staffList = this.task.config.staff || [];
      }
    },

    // 创建/保存任务
    async updateTask(){
      let params = {
        taskName: this.task.name,
        targetTask: this.task.targetType,
        times: this.task.targetCount,
        content: this.task.contentLimit,
        startTime: this.$dayjs(this.task.times[0]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: this.$dayjs(this.task.times[1]).format('YYYY-MM-DD HH:mm:ss'),
        config: this.task.config || {}
      };

      // 组织员工数据
      if(this.task.staffList.length > 0){
        this.task.staffList.map((e, index) => {
          if(params.emps){
            params.emps += `${e.id},`;
          }else{
            params.emps = `${e.id},`;
          }
        });
      };
      params.config.staff = this.task.staffList;

      // 组织内容数据
      if(this.task.contentLimit){
        params.contentUrl = this.task.matterItem.item.id;
        params.config.matter = this.task.matterItem;
      }
      
      params.config = JSON.stringify(params.config);
      let res;
      if(this.$route.query.id){
        res = await this.$ZGManager.updateTask(this.$route.query.id, params);
      }else{
        res = await this.$ZGManager.creatTask(params);
      }
      if(res.status != 200) return;
      this.$message.success(this.$route.query.id ? '保存成功' : '创建成功');
      if(!this.$route.query.id){
        this.$router.replace({query: {id: res.data.id}});
      }
    },

    // 选择员工
    selectStaff(){
      dialogComponents.show({
        type: "selectStaff",
        staffList: this.task.staffList,
        selected: (item) => {
          this.task.staffList = item;
        }
      });
    },
    
    // 选择素材
    selectMatter(){
      dialogComponents.show({
        type: "selectMatter",
        selectedList: [],
        selected: (item) => {
          this.task.matterItem = item;
        }
      });
    },

    // 是否能被编辑
    isDisabled(type){
      if(!this.$route.query.id) return false;
      if(this.$dayjs(this.task.times[0]).isBefore(this.$dayjs())){
        return true;
      }else{
        return false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  font-size: 14rx;
  padding-left: 50px;
}
.item-content{
  width: calc(100% - 50px);
  max-width: 800px;
  margin-top: 20px;
  .select-content{
    display: inline-block;
    width: 300px;
    height: 84px;
    background-color: #f5f5f5;
    text-align: center;
    cursor: pointer;
    .tips{
      line-height: 84px;
    }
  }
  .content-item{
    margin-top: 12px;
    text-align: left;
    img{
      width: 60px;
      height: 60px;
      margin-left: 12px;
      display: inline-block;
      border: 0.5px solid #ccc;
      box-sizing: border-box;
    }
    span{
      float: right;
      width: 212px;
      margin-right: 12px;
      line-height: 20px;
      vertical-align: top;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .tips{
    color: #bbb;
  }
}
</style>