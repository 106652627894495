<template>
  <div class="content-item">
    <img :src="img">
    <span>{{name}}</span>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: 'https://img0.qll-times.com/cdn/zgicon/file-none.png'
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.content-item{
  margin-top: 12px;
  text-align: left;
  img{
    width: 60px;
    height: 60px;
    margin-left: 12px;
    display: inline-block;
    border: 0.5px solid #ccc;
    box-sizing: border-box;
  }
  span{
    float: right;
    width: 212px;
    margin-right: 12px;
    line-height: 20px;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
</style>